import { Button } from '@ubnt/ui-components'
import { Text_Augmented } from '@ubnt/ui-components/aria'
import { useState } from 'react'
import { TICKET_STATUS_LABELS } from 'rma-shared/tickets'
import { EscalatedType } from 'rma-shared/types/tickets'
import { useRequest } from '../../hooks'
import type { Shared_Ticket_DetailsFragment as Ticket } from './__generated__/Details'
import { ContactSupportAboutTicket } from './contact-support'
import { TicketDetailsSupportPanel } from './ticket-details-support-panel'

export function GetSupportNotification({
  ticket,
  refetchTicket,
}: {
  ticket: Ticket
  refetchTicket?: (redirectToMessaging: boolean) => Promise<void>
}) {
  const [requestUiSupport, { loading: requestUiSupportLoading }] = useRequest('requestUiSupport')

  const [loading, setIsLoading] = useState(requestUiSupportLoading)
  const [showCustomerSupportModal, setShowCustomerSupportModal] = useState(false)
  const [alreadySubmitted, setAlreadySubmitted] = useState<{ prevTitle: string }>()

  if (alreadySubmitted) {
    return (
      <TicketDetailsSupportPanel
        title={<>{alreadySubmitted.prevTitle}</>}
        message={
          <>
            <Text_Augmented>Your ticket is escalated to Ubiquiti for support.</Text_Augmented>
          </>
        }
      />
    )
  }

  if (
    ticket.offerUiSupport.type === EscalatedType.None ||
    ticket.offerUiSupport.type === EscalatedType.AlreadyDisputed
  ) {
    return null
  }

  const { title, info } = getContent(ticket)

  const handleOnCustomerSupportComplete = async () => {
    setShowCustomerSupportModal(false)
    setAlreadySubmitted({ prevTitle: title })

    if (refetchTicket) {
      await refetchTicket(true)
    }
  }

  const handleClick = async () => {
    if (customerSupportAvailable(ticket.offerUiSupport.type)) {
      setShowCustomerSupportModal(true)
      return
    }

    setIsLoading(true)

    try {
      await requestUiSupport({
        ticketId: ticket.id,
      })

      setAlreadySubmitted({ prevTitle: title })

      if (refetchTicket) {
        await refetchTicket(true)
      }
    } catch (e) {
      setIsLoading(false)
      // Show notification or something that would let user know that the operation failed
    }
  }

  return (
    <TicketDetailsSupportPanel
      title={<>{title}</>}
      message={
        <>
          <Text_Augmented>{info}</Text_Augmented>

          <div className="mt-8">
            <Button
              full={false}
              variant="inline"
              loader={loading ? 'dots' : undefined}
              onClick={() => void handleClick()}
            >
              {getButtonText(ticket.offerUiSupport.type)}
            </Button>
          </div>

          {showCustomerSupportModal && (
            <ContactSupportAboutTicket
              ticketId={ticket.id}
              supportedByName={ticket.supportByName}
              isUbiquiti={ticket.supportByUbiquiti}
              onDone={handleOnCustomerSupportComplete}
              onClose={() => setShowCustomerSupportModal(false)}
              disputeType={
                ticket.offerUiSupport.type === EscalatedType.CustomerSupportDeclined ? 'declination' : 'standard'
              }
            />
          )}
        </>
      }
    />
  )
}

function getContent(ticket: Ticket): { title: string; info: JSX.Element | string } {
  const support = ticket.offerUiSupport
  const defaultTitle = `${ticket.supportByReseller ? 'Reseller' : 'Distributor'} Taking Too Long?`

  switch (support.type) {
    case EscalatedType.CustomerSupportBackordered:
      return {
        title: 'Your RMA replacement item is backordered.',
        info:
          'We’re working to get it shipped to you as soon as possible. If you have any questions or need assistance in the meantime, please don’t hesitate to reach out to us via the link below.',
      }

    case EscalatedType.CustomerSupportDeclined:
      if (support.isSupportedByUI) {
        return {
          title: 'Your RMA request was declined.',
          info: support.reason ? (
            <>
              After careful review, we’re unable to approve your request at this time due to the following reason:{' '}
              <Text_Augmented color="red-06">{support.reason}</Text_Augmented>. If you have questions or believe there’s
              been an error, please reach out to us via the link below
            </>
          ) : (
            'After careful review, we’re unable to approve your request at this time. If you have questions or believe there’s been an error, please reach out to us via the link below'
          ),
        }
      }

      return {
        title: 'Dispute a Declined RMA Request',
        info: `We see that your request was declined. If you would like to dispute the reseller's decision, contact
          us to share more details about your situation so we can recommend next steps.`,
      }

    case EscalatedType.Default:
      return {
        title: defaultTitle,
        info:
          'We apologize for the inconvenience the reseller has caused by not processing this RMA ticket within two business days. Please click the Get Support link below so we can expedite your ticket.',
      }

    case EscalatedType.Extended:
    case EscalatedType.Automatic:
      return {
        title: defaultTitle,
        info:
          'We apologize for the inconvenience the reseller has caused by not processing this RMA ticket within seven business days. Please click the Get Support link below so we can expedite your ticket.',
      }
    case EscalatedType.CustomerSupportAccepted:
      return {
        title: defaultTitle,
        info:
          'We see that this ticket has been in the Approved state for 21 business days. Click below to give us more details about this situation so we can address it with the reseller on your behalf, or reassign your ticket to us to expedite your service.',
      }
    case EscalatedType.CustomerSupportProcessing:
      return {
        title: defaultTitle,
        info: `We see that this ticket has been in the ${
          TICKET_STATUS_LABELS[ticket.status]
        } state for more than 7 business days. Click below to give us more details about this situation so we can address it with the reseller on your behalf, or reassign your ticket to us to expedite your service.`,
      }

    default:
      return { title: defaultTitle, info: '' }
  }
}

function customerSupportAvailable(type: EscalatedType) {
  return (
    type === EscalatedType.CustomerSupportAccepted ||
    type === EscalatedType.CustomerSupportProcessing ||
    type === EscalatedType.CustomerSupportBackordered ||
    type === EscalatedType.CustomerSupportDeclined
  )
}

function getButtonText(escalatedType: EscalatedType) {
  if (customerSupportAvailable(escalatedType)) {
    return 'Contact Us'
  }

  return 'Get support'
}
