import type { FileUpload } from 'graphql-upload'
import type { CountryCode } from 'libphonenumber-js'
import type { TicketStatus } from '../tickets/constants'
import type {
  CompanyAddressId,
  CompanyId,
  DeviceId,
  NetsuiteFailureCategoryId,
  NetsuiteProductId,
  ShipmentUId,
  TicketId,
} from './brands'

export enum EscalatedType {
  None = 'None',
  AlreadyDisputed = 'AlreadyDisputed',
  Default = 'Default',
  Extended = 'Extended',
  Automatic = 'Automatic',
  CustomerSupportAccepted = 'CustomerSupportAccepted',
  CustomerSupportProcessing = 'CustomerSupportProcessing',
  CustomerSupportDeclined = 'CustomerSupportDeclined',
  CustomerSupportBackordered = 'CustomerSupportBackordered',
}

export enum CreatedVia {
  All = 'All',
  Direct = 'Direct',
  Rma = 'Rma',
  Var = 'Var',
}

export const ReassignToUIReason = {
  ExcessiveProcessingTime: 'Excessive Processing Time',
  MistakenlySentToDistributor: 'Ticket Mistakenly Sent to Distributor',
  DistributorDoesNotHaveStock: 'Distributor Doesn’t Have Item in Stock',
  NoResponseGivenToCustomer: 'No Response Given to Customer',
  DeniedRMAServiceToCustomer: 'Denied RMA Service to Customer',
  Other: 'Other',
}

export type ReassignToUIReasonKey = keyof typeof ReassignToUIReason

export enum NetSuiteTicketStatus {
  Error = 'Error',
  Processing = 'Processing',
  Success = 'Success',
}

export type ExtraData = {
  notes?: string
  files: FileUpload[]
  addressId: CompanyAddressId
  cancelBlocked: boolean
} & Record<string, unknown>

export interface TicketData {
  id: TicketId
  deviceId: DeviceId
  createdAt: Date
  updatedAt: Date
  escalatedAt: Date | null
  firstResponseAt: Date | null
  status: TicketStatus
  failureCategory: NetsuiteFailureCategoryId | null
  rating: number
  description: string
  supportByName: string
}

export interface DeviceData {
  id: DeviceId
  sku: string
  mac: string
  isUnifiCare: boolean
}

export interface TicketInfo {
  ticket: TicketData
  device: DeviceData
}

export interface TicketStatusUpdate {
  ticketId: TicketId
  status: TicketStatus
}

export interface MultiFilterItem {
  value: string
  label: string
}

export interface CustomerTicketsFilters {
  products?: MultiFilterItem[]
  sku?: MultiFilterItem[]
  createdAfter?: Date
  createdBefore?: Date
  updatedBefore?: Date
  nsStatus?: NetSuiteTicketStatus
  rating?: number
  countries?: MultiFilterItem[]
  search?: string
  statuses?: MultiFilterItem[]
  supportByPartyId?: number
  uiCare?: boolean
  endCustomer?: boolean
  createdVia?: CreatedVia
  showSpam?: boolean
}

export type NsErrorStatus = 'Success' | 'Failure' | 'Processing' | ''

export interface CustomerTicket {
  id: TicketId
  coreProductId: NetsuiteProductId
  mac: string | null
  image: string
  sku: string
  customer: string
  supportBy: string
  createdAt: Date
  status: TicketStatus
  rating: number
  shipmentUId: ShipmentUId | null
  shipmentFromUId: ShipmentUId
  shipmentToUbiquiti: boolean
  isUnifiCare: boolean
  nsPushState?: NsErrorStatus
  nsError: string | null
  nsShipmentError: string | null
  supportByUbiquiti: boolean
  supportById: CompanyId

  // TODO: for CSV, should separate to a different type
  customerEmail: string
  customerCountry: string
  soldBy: string
  issueCategory: string
  description: string
  isEndCustomer: boolean
  hasSellerId: boolean
  replacementTrakingNumber: string
  dateCode: string
}

export type SortableColumns =
  | 'id'
  | 'sku'
  | 'mac'
  | 'customer'
  | 'supportBy'
  | 'createdAt'
  | 'status'
  | 'rating'
  | 'shipmentUId'
  | 'isUnifiCare'
  | 'nsError'

export type PageInfo = {
  total: number
  offset: number
  limit: number
  sortBy?: string
  sortDirection?: 'asc' | 'desc'
}

export enum QueryTicketType {
  All = 'All',
  Closed = 'Closed',
  Declined = 'Declined',
  InProgress = 'InProgress',
  Open = 'Open',
  Pending = 'Pending',
  Shipped = 'Shipped',
}

export type IncomingTicket = {
  id: TicketId
  createdAt: string
  closedAt: string
  sku: string
  mac: string
  coreProductId: NetsuiteProductId
  image: string
  createdBy: string
  status: TicketStatus
  rating: number
  trackingNumber: string
}

export type IncomingTicketTotals = {
  All: number
  Closed: number
  Declined: number
  InProgress: number
  Open: number
  Pending: number
  Shipped: number
}

export type CustomerTicketsPaginated = {
  data: CustomerTicket[]
  pageInfo: PageInfo
}

export type FailureCategory = {
  id: NetsuiteFailureCategoryId
  name: string
  special?: boolean
}

export const FailureCategories: FailureCategory[] = [
  { id: '211', name: 'Cosmetic' },
  { id: '210', name: 'Power Failure' },
  { id: '212', name: 'Software' },
  { id: '213', name: 'Port Failure' },
  { id: '214', name: 'LED/LCD failure' },
  { id: '215', name: 'Dead on Arrival' },
  { id: '310', name: 'Reboot Issue', special: true },
  { id: '311', name: 'Range' },
  { id: '312', name: 'Connectivity' },
  { id: '216', name: 'Other' },
]

export const FailureCategoriesMap = FailureCategories.reduce((acc, entry) => {
  acc[entry.id] = entry.name
  return acc
}, {} as Record<string, string>)

export const getFailureCategory = (id: string) => {
  const category = FailureCategories.find((entry) => entry.id === id)
  return category ? category.name : '<Unknown>'
}

export interface TicketStatusDataBasic {
  status: Exclude<
    TicketStatus,
    TicketStatus.Accepted | TicketStatus.InFulfilment | TicketStatus.Shipped | TicketStatus.ShippedPendingReceipt
  >
  createdAt: Date
  notes: string
}

export interface TicketStatusAccepted {
  status: TicketStatus.Accepted
  createdAt: Date
  notes: string
  addressId: CompanyAddressId
}

export interface TicketStatusInFulfilment {
  status: TicketStatus.InFulfilment
  createdAt: Date
  failureCategory: NetsuiteFailureCategoryId
  failureDescription: string
  notes: string
  files?: Promise<FileUpload>[]
}

export interface TicketStatusShipped {
  status: TicketStatus.Shipped | TicketStatus.ShippedPendingReceipt
  createdAt: Date
  shippingCompany: string
  trackingNumber: string
  notes: string
  files?: Promise<FileUpload>[]
}

export type TicketStatusData =
  | TicketStatusDataBasic
  | TicketStatusAccepted
  | TicketStatusInFulfilment
  | TicketStatusShipped

export enum PartyType {
  Company = 'Company',
  User = 'User',
}

export enum CompanyType {
  distributor = 'distributor',
  reseller = 'reseller',
  ubiquiti = 'ubiquiti',
}

export type TicketPresetCore = {
  ticketId: TicketId
  productId: NetsuiteProductId
  mac: string
  country: string
}

export type TicketEmailPreset = {
  type: 'device-not-received' | 'accepted-reminder'
} & TicketPresetCore

export type TicketEmailCompanyPreset = {
  type: 'ticket-escalated-to-ui'
  companyType: CompanyType
} & TicketPresetCore

export type TicketBusinessDaysPreset = {
  type: 'ticket-reminder' | 'ticket-reassigned-to-ui'
  companyType: CompanyType
  businessDays: number
} & TicketPresetCore

export type TicketPreset = TicketEmailPreset | TicketEmailCompanyPreset | TicketBusinessDaysPreset

export const ShipmentsEmailTemplateTypes = ['shipment-approved'] as const
export type ShipmentsEmailTemplateType = typeof ShipmentsEmailTemplateTypes[number]

export const AlertsEmailTemplateTypes = [
  'ui-care',
  'ui-care-backordered',
  'backordered',
  'pending',
  'advanced-different-country',
] as const
export type AlertsEmailTemplateType = typeof AlertsEmailTemplateTypes[number]

export const UbiquitiEmailTemplateTypes = ['accepted'] as const
export type UbiquitiEmailTemplateType = typeof UbiquitiEmailTemplateTypes[number]

export interface TicketStatusEmailPreset {
  type: 'ticket-status'
  ticketId: TicketId
  status: TicketStatus
  productId: NetsuiteProductId
  mac: string
  customerType: PartyType
  customerCompanyType: CompanyType | undefined
  companyType: CompanyType
  companyCountry: string
  country: string
  notes: string
}

export interface ShipmentsEmailPreset {
  type: 'shipments'
  template: ShipmentsEmailTemplateType
  shipmentUId: ShipmentUId
  country: CountryCode
  approvedCount: number
  outOfWarrantyCount: number
}

export interface AlertsEmailPreset {
  type: 'alerts'
  alertsTemplate: AlertsEmailTemplateType
  ticketId: TicketId
  ticketsCount: number
}

export interface UbiquitiEmailPreset {
  type: 'ubiquiti'
  ubiquitiTemplate: UbiquitiEmailTemplateType
  ticketId: TicketId
  ticketStatus: TicketStatus
  country: string
  customerName: string
  handledByUI: boolean
}

export type Preset =
  | TicketPreset
  | TicketStatusEmailPreset
  | ShipmentsEmailPreset
  | AlertsEmailPreset
  | UbiquitiEmailPreset

export interface ShipmentInfo {
  shippingCompany: string
  shippingCompanyOther?: string
  trackingNumber: string
}

export interface TicketSupport {
  type: EscalatedType
  reason: string
  isSupportedByUI: boolean
}
