import { ExclamationMarkIconL } from '@ubnt/icons'
import { Button, Modal, Text } from '@ubnt/ui-components'
import cssVariables from '@ubnt/ui-components/styles/variables'
import { useState } from 'react'
import { TicketStatus } from 'rma-shared/tickets'
import type { TicketId } from 'rma-shared/types/brands'
import styled from 'styled-components'
import { Link } from '..'
import { useToast } from '../../contexts/ToastContext'
import { useRequest } from '../../hooks'
import { AlertIcon } from '../icons/AltertIcon'
import type { Shared_Ticket_DetailsFragment as Ticket } from './__generated__/Details'
import { ContactSupportAboutTicket } from './contact-support'
import type { TrackingNumberType } from './utils/get-tracking-number-type'

export function TrackingNumber({
  ticket,
  trackingNumberType,
}: {
  ticket: Ticket
  trackingNumberType: TrackingNumberType
}) {
  const { showNotification } = useToast()

  const [downloadPackingSlip, { loading: downloadingPackingSlip }] = useRequest('downloadPackingSlip', { retries: 1 })
  const [downloadInvoice, { loading: downloadingInvoice }] = useRequest('downloadInvoice', { retries: 1 })

  const [showShippingInstructions, setShowShippingInstructions] = useState(false)
  const [showSupport, setShowSupport] = useState(false)

  const replacementShippedNotReceived = isReplacementShippedNotReceived(ticket.status)
  const isLoading = downloadingPackingSlip || downloadingInvoice
  // Commenting out this code as it's expected to be used once again later. See: https://ubiquiti.atlassian.net/browse/RP-2465
  // > Note: Invoice will be later used for customers from other countries, so do not delete the code. Just remove it for UK customers.
  // const isUK = ticket.shipmentAddress.country === 'GB'
  // const documentType = isUK ? 'invoice' : 'packing-slip'
  const documentType = 'packing-slip' as 'invoice' | 'packing-slip'
  const documentName = documentType === 'invoice' ? 'Invoice' : 'Packing Slip'

  async function handleDownload(ticketId: TicketId) {
    try {
      if (documentType === 'invoice') {
        await downloadInvoice({
          ticketId,
        })
        return
      }

      await downloadPackingSlip({
        ticketId,
      })
    } catch (err) {
      showNotification({
        id: 'proforma-invoice-error',
        title: `Could not download ${documentName}`,
        details: 'Please try again later or contact support if the issue persists.',
        icon: () => (
          <ExclamationMarkIconL color={cssVariables.motifs.light.warning} size="navigation" style={{ marginTop: 19 }} />
        ),
        duration: 10000,
      })
    }
  }

  return (
    <div className="p-20 mb-16 s-12 bg-white border-radius-6">
      <div className="flex align-center">
        <AlertIcon variant="fill" style={{ marginRight: '9px' }} />
        <Text weight="bold" size="body" color="primary">
          Awaiting Your RMA Item
        </Text>
      </div>

      <Text color="primary" size="body">
        {!replacementShippedNotReceived && (
          <span>We will continue processing your request once it&apos;s received.</span>
        )}

        {replacementShippedNotReceived && (
          <span>
            Your replacement item has been shipped, but we still haven&apos;t received the original. Please return it as
            soon as possible. If you have and the shipment has been designated as delivered, please contact us{' '}
            <Link onClick={() => setShowSupport(true)}>here</Link>.
          </span>
        )}
      </Text>

      {trackingNumberType === 'show' && (
        <div className="gap-4">
          <Link className="block" onClick={() => setShowShippingInstructions(true)}>
            View Shipping Instructions
          </Link>

          {isLoading && <Button loader="dots" size="small" style={{ height: '22px' }} />}

          {!isLoading && (
            <Link className="block" onClick={() => handleDownload(ticket.id)}>
              Download {documentName}
            </Link>
          )}
        </div>
      )}

      {showSupport && (
        <ContactSupportAboutTicket
          ticketId={ticket.id}
          supportedByName={ticket.supportByName}
          isUbiquiti={ticket.supportByUbiquiti}
          onDone={() => {
            setShowSupport(false)
          }}
          onClose={() => setShowSupport(false)}
          disputeType="declination"
        />
      )}

      <Modal
        isOpen={showShippingInstructions}
        size="small"
        title="Shipping Instructions"
        actions={[
          {
            text: <span data-testid="unable-to-find-mac-id-modal-close-btn">Close</span>,
            variant: 'tertiary',
            onClick: () => setShowShippingInstructions(false),
          },
        ]}
        onRequestClose={() => setShowShippingInstructions(false)}
      >
        <div>
          <p>
            Print{' '}
            <Link onClick={() => handleDownload(ticket.id)}>
              {documentType === 'invoice' ? 'invoice' : 'packing slip'}
            </Link>{' '}
            and place it in the return package.
            <br />
            Ensure that the device&#39;s MAC address label is legible before packing it.
          </p>

          <Header>While packing the device, please note that:</Header>
          <UnorderedList>
            <li>You do not need to include the original packaging or any accessories (e.g., mounts, screws, etc).</li>
            <li>All removable hard drive(s) that were part of your original purchase must also be returned.</li>
            <li>
              If you are returning a router from an AmpliFi kit, the entire kit must be returned since a new router will
              not pair with the original kit&#39;s mesh point.
            </li>
          </UnorderedList>

          <p>
            <Header>Shipping</Header>
            <br />
            Please send the return package to the following address:
          </p>
          <AddressBlock>
            {ticket.warehouseAddress.country === 'US' && (
              <>
                {ticket.warehouseAddress.companyName}
                <br />
              </>
            )}
            {ticket.warehouseAddress.name}
            <br />
            {ticket.warehouseAddress.address1}
            <br />
            {ticket.warehouseAddress.city}, {ticket.warehouseAddress.state ? `${ticket.warehouseAddress.state} ` : ''}{' '}
            {ticket.warehouseAddress.zipcode}
            <br />
            {ticket.warehouseAddress.country}
            <br />
            {ticket.warehouseAddress.phoneNumber}
          </AddressBlock>

          <Header>Please note that:</Header>
          <UnorderedList>
            <li>RMA submissions are valid for 30 days.</li>
            <li>If these guidelines are not followed, the RMA may be delayed.</li>
            <li>
              If shipping internationally, you must declare the shipment as no commercial value/returned for repair to
              avoid incurring import fees. If this is not done, the shipment will be refused or delayed.
            </li>
          </UnorderedList>
        </div>
      </Modal>
    </div>
  )
}

const isReplacementShippedNotReceived = (status: TicketStatus) => {
  const shippedNotReceivedStatuses: TicketStatus[] = [
    TicketStatus.ShippedPendingReceipt,
    TicketStatus.ReplacementShippedPendingReceipt,
    TicketStatus.Backordered,
    TicketStatus.InFulfilment,
  ]

  return shippedNotReceivedStatuses.includes(status)
}

const Header = styled(Text).attrs({ weight: 'bold', size: 'body' })`
  margin-bottom: 8px;
`

const AddressBlock = styled.div`
  padding: 20px;
  margin: 20px 0;
  background: rgba(250, 251, 251, 1);
  border-radius: 4px;
`

const UnorderedList = styled.ul`
  margin: 0;
  padding: 0 0 0 14px;
`
