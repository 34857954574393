import NotificationsSidebar from '@shared-ui/components/notifications/NotificationsSidebar'
import { DetailsTab, LogTab, MessagingTab, Ticket as TicketShared, useTicketStore } from '@shared-ui/components/Ticket'
import { useNestedPath } from '@shared-ui/hooks'
import type { TicketId } from '@shared/types/brands'
import { EscalatedType } from '@shared/types/tickets'
import { StarIcon } from '@ubnt/icons'
import { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTicketQuery } from './__generated__/Ticket'
import { Rate } from './Ticket/Rate'

export const RateTab = {
  id: 'rate',
  path: '/rate',
  icon: <StarIcon size="navigation" />,
  component: Rate,
}

export interface UrlParam {
  sidebarParam: string
}

interface SidebarProps {
  onClose: () => void
}

const RmaDetailsTab = {
  ...DetailsTab,
  props: {
    canCancelTicket: true,
  },
}

export default function Sidebar({ onClose }: SidebarProps) {
  const { sidebarParam } = useParams<UrlParam>()

  if (sidebarParam === 'notifications') {
    return <NotificationsSidebar onClose={onClose} />
  }

  return <TicketSidebar ticketId={sidebarParam} onClose={onClose} />
}

function TicketSidebar({ ticketId, onClose }: { ticketId: TicketId; onClose: () => void }) {
  const { data, loading, error, refetch } = useTicketQuery({
    variables: { id: ticketId },
  })

  const [redirectUrl, setRedirectUrl] = useState('')

  const nested = useNestedPath()
  const history = useHistory()

  const isLoading = loading

  function refetchTicket(redirectToMessaging: boolean) {
    void refetch().then(() => {
      if (redirectToMessaging) {
        setRedirectUrl('/messaging')
      }
    })
  }

  useEffect(() => {
    useTicketStore.getState().reset()
  }, [ticketId])

  useEffect(() => {
    if (redirectUrl) {
      history.push(`${nested(redirectUrl)}`)
    }
  }, [redirectUrl])

  const tabs = useMemo(() => {
    console.log(data?.ticket?.supportByUbiquiti, data?.ticket?.offerUiSupport?.type)
    const showMessagingTab =
      data?.ticket?.supportByUbiquiti === false || data?.ticket?.offerUiSupport?.type === EscalatedType.AlreadyDisputed

    return [RmaDetailsTab, LogTab, showMessagingTab ? MessagingTab : null, RateTab].filter(
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
      <T extends unknown>(tab: T): tab is NonNullable<T> => !!tab,
    )
  }, [data])

  return (
    <TicketShared
      tabs={tabs}
      loading={isLoading}
      error={error}
      ticket={data?.ticket ?? undefined}
      refetchTicket={(redirectToMessaging: boolean) => void refetchTicket(redirectToMessaging)}
      onClose={onClose}
    />
  )
}
