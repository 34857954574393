import { WarrantyStatus } from '../../../types/enums'

export function isProofOfPurchaseRequired({
  sellerId,
  mac,
  name,
  isSoldByUbiquiti,
  isAdvancedFlowAvailable,
  isUnifiCare = false,
  isSellerInactive = false,
  isUicareManual = false,
  warrantyStatus = WarrantyStatus.Unknown,
}: {
  sellerId?: string | null
  mac: string | null
  name: string
  isSoldByUbiquiti: boolean
  isUnifiCare: boolean
  isUicareManual: boolean
  isSellerInactive?: boolean
  isAdvancedFlowAvailable?: boolean
  warrantyStatus: WarrantyStatus
}) {
  if (!mac) {
    return true
  }

  const sku = name.toLocaleLowerCase()
  if (sku.indexOf('u6-lr') === 0 || sku.indexOf('uck-g2-plus') === 0) {
    if (!sellerId) {
      return true
    }
    return false
  }

  if (!sellerId) {
    return true
  }

  if (isSellerInactive) {
    return true
  }

  if (isUnifiCare) {
    return isUicareManual || isSoldByUbiquiti
  }

  if (isAdvancedFlowAvailable) {
    return true
  }

  return warrantyStatus !== WarrantyStatus.Active || isSellerInactive
}
